





































































































import { api } from "@/api/api";
import { ApiCreateCostDto, ApiGetCostTypeDto } from "@/api/generated/Api";
import BaseModalForm from "@/components/shared/modal/BaseModalForm.vue";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { deepCloneObject } from "@/shared/helpers/deepCloneHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { useRouteComputed, useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, PropType, ref } from "@vue/composition-api";
import Vue from "vue";
import { TeachingMethods } from "@/shared/enums/teaching-methods.enum";
import { ExpenseType } from "@/shared/enums/expenseType.enum";

interface ComponentProps {
  headline: string;
  modalType: ModalType;
  costTypes: ApiGetCostTypeDto[];
  existingItemModal: ApiCreateCostDto;
  elementId: number;
}

export default defineComponent({
  name: "ExpensesModal",
  components: { BaseModalForm },
  props: {
    modalType: {
      type: String as PropType<ModalType>,
      required: true,
    },
    headline: {
      type: String,
      required: true,
    },
    costTypes: {
      type: Array,
      required: true,
    },
    existingItemModal: {
      type: Object,
    },
    elementId: {
      type: Number,
    },
  },
  setup(props: ComponentProps, { emit, refs }) {
    const store = useStore<StoreState>();
    const route = useRouteComputed();
    // the names and IDs collected here are from ApiGetCostTypeDto[] but is currently hardcoded as the DTO contains
    // categories that should not be included in the dropdowns
    const costTypesExpenses = [{ name: "Andre utgifter", id: 512 }];
    const costTypesSalaryCost = [
      { name: "Undervisningstimer", id: 510 },
      { name: "Andre timer", id: 511 },
    ];

    const costModel = ref<ApiCreateCostDto>({
      name: "",
      description: "",
      price: 0,
      payType: 0,
      articleNo: "",
      teachingMethod: "",
      deductionCode: 0,
      isActive: true,
      costTypeId: props.costTypes[0].id,
    });

    const existingItemModal: ApiCreateCostDto = deepCloneObject(props.existingItemModal as ApiCreateCostDto);
    if (props.modalType === ModalType.Edit || props.modalType === ModalType.Display) {
      costModel.value = existingItemModal;
    }

    const handleSubmit = () => {
      const isValid = (refs.connectForm as Vue & { validate: () => boolean }).validate();
      if (!isValid) {
        return;
      }
      if (props.modalType === ModalType.Add) {
        addCost();
      } else if (props.modalType === ModalType.Edit) {
        editCost();
      } else {
        openNotification(store, NotificationItemType.Error, "Det skjedde en feil");
      }
    };

    const addCost = async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        await api.economy.createCostAsync(costModel.value);
        openNotification(store, NotificationItemType.Success, "Kostnad lagt til");
        emit("close");
      });
    };

    const editCost = async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        const id = props.elementId as number;
        if (!id || id < 0) {
          throw new Error("Invalid id");
        }
        await api.economy.updateCostAsync(id, costModel.value);
        openNotification(store, NotificationItemType.Success, "Kostnad oppdatert");
        emit("close");
      });
    };
    return {
      costModel,
      ExpenseType,
      route,
      costTypesSalaryCost,
      costTypesExpenses,
      handleSubmit,
      costName: computed(() => route.value.name),
      teachingMethodsList: computed(() => Object.values(TeachingMethods)),
      readonly: computed(() => props.modalType === ModalType.Display),
    };
  },
});
